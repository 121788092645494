import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import { graphql, Link, PageProps, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import React, { FC } from 'react';

// import './style.scss';

type ProfileProps = {
  name: string;
  title: string;
  path: string;
  image: ImageDataLike;
};

const Profile: FC<ProfileProps> = ({ name, title, path, image }) => {
  return (
    <Link
      to={path}
      className="group transition-all transform scale-100 hover:scale-125 hover:shadow-2xl cursor-pointer flex flex-col items-center m-8"
    >
      <GatsbyImage
        className="rounded-full group-hover:rounded-none"
        image={getImage(image)}
        alt={name}
      />
      <h2 className="mt-4 text-2xl font-emp font-semibold">{name}</h2>
      <p className="my-4 font-medium">{title}</p>

      <p className="mt-4 p-2 font-medium bg-primary text-white w-full text-center opacity-0 group-hover:opacity-100">
        See profile
      </p>
    </Link>
  );
};

const Team: FC<PageProps> = () => {
  const {
    membersQuery: { edges: members },
  } = useStaticQuery(graphql`
    query TeamQuery {
      membersQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//team/" } }
        sort: { fields: [frontmatter___weight], order: ASC }
      ) {
        edges {
          node {
            frontmatter {
              name
              path
              image {
                childImageSharp {
                  gatsbyImageData(
                    width: 200
                    placeholder: TRACED_SVG
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              jobTitle
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        url="team"
        title="The team behind OptiOffer"
        description="The team behind OptiOffer"
      />

      <div className="container m-auto">
        <section className="mb-20">
          <h1 className="header-1 text-left p-4 pt-24 lg:pt-32 pb-12 lg:pb-24">
            Meet the Team
          </h1>

          <div className="flex flex-wrap justify-around mt-8">
            {members.map(({ node: { frontmatter: it } }) => (
              <Profile
                key={it.path}
                path={it.path}
                name={it.name}
                title={it.jobTitle}
                image={it.image}
              />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Team;
